import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'
import Img from 'gatsby-image'

const SerendipityPage = props => {

  const imageStruct = props.data.allImagesJson.edges[0].node.childrenImagesJson.reduce((acc, curr) => {
    acc.push({
      "name": curr.eventName,
      "desc": curr.eventDesc,
      "groups": curr.groupedfiles.map((grp) => {
        return grp.reduce((iacc, icurr) => {
          iacc.push(icurr.childImageSharp.fluid)
          return iacc
        }, [])
      })
    })
    return acc
  }, [])[0]

  return (
    <Layout>
      <div class="photoGridWrap">
        <div className="photoGridName"> {imageStruct.name} </div>
        {imageStruct.desc &&
          imageStruct.desc.map((tt) => (
            <div className="photoGridDesc">
              {tt}
            </div>
          ))
        }
        <div className="photoGridDesc">
          For more information about Serendipity projects and workshops, check out <a href='https://www.cieserendipity.com/en/'>www.cieserendipity.com</a>
        </div>

        {imageStruct.groups.map((images) => (
          <div className={(function(t) {
              switch(t.length) {
                case 1:
                  return "photoRow singleColumn";
                case 2:
                  return "photoRow doubleColumn";
                default:
                  return "photoRow multiColumn";
              }
            })(images)
          }>

          {images.map((i) => (
            <div className="photoColumn">
              <Img fluid={i} />
            </div>
          ))}


        </div>
        ))}


      </div>
    </Layout>
  )
}

export const SerendipityImageQuery = graphql`
  query SerendipityImageQuery {
    allImagesJson: allFile(
      filter: { relativePath: { eq: "grid/serendipity/images.json" } }
    ) {
      edges {
        node {
          childrenImagesJson {
            eventName
            eventDesc
            groupedfiles {
              childImageSharp {
                fluid(maxWidth: 2280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withIntl(SerendipityPage)
